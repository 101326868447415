import { React, useEffect, useState, useRef } from 'react';
import { Parallax, Background } from "react-parallax";

import '../custom.scss';

function Photography() {
    return (
        <div id="photography" className="row align-items-md-stretch">
            <div className="col-md-6 rounded-3">
                <div className="h-100 p-5 rounded-3">
                
                
                </div>
            </div>
            <div className="col-md-6">
                <div className="h-100 p-5 rounded-3">
                
                
                </div>
            </div>
        </div>
    )
}

export default Photography;